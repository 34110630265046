import React from 'react'
import {
  CtaBar,
  LeshenPhoneCTA,
  Typography,
  useMapi,
} from '@leshen/gatsby-theme-leshen'

const SeoCtaBar = () => {
  const { requestId } = useMapi()

  return (
    <CtaBar
      backgroundColor="secondary"
      textColor="white"
      sticky
      leftBlock={<Typography>Order DIRECTV today</Typography>}
      middleBlock={
        <LeshenPhoneCTA color="light" outlined requestId={requestId}>
          Call
        </LeshenPhoneCTA>
      }
    />
  )
}

export default SeoCtaBar
